<script>
import store from '@/store'
export default {
  created () {
    const { query } = this.$route
    const homePage = store.getters.homePage
    this.$router.replace({ path: homePage, query })
  },
  render (h) {
    return h() // avoid warning message
  }
}
</script>

<style>

</style>
